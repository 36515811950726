import React from 'react'
import 'lazysizes'
import { StaticQuery, graphql } from 'gatsby'

const Image = (props) => (
    <StaticQuery
    query={graphql`
      {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1920) {
                  src
                  srcSet
                }
                fixed(width: 100) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
        const image = data.images.edges.find(n => {
          return n.node.relativePath.includes(props.filename);
        });

        if (!image) { return null }
        return (
          <img
            className="lazyload"
            alt={props.alt}
            src={ image.node.childImageSharp.fixed.src }
            data-src={ image.node.childImageSharp.fluid.src }
            data-srcset={  image.node.childImageSharp.fluid.srcSet } />          
        )
    }}
  ></StaticQuery>
)


export default Image
